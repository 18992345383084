var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Nuevos clientes "),(_vm.self.formData.solicitudes)?_c('span',[_vm._v("("+_vm._s(_vm.self.formData.solicitudes.length)+")")]):_vm._e()])]),_c('div',{staticClass:"card-body",staticStyle:{"height":"230px","position":"relative"}},[_c('field',{ref:"solicitudes",staticClass:"zebraStyle",staticStyle:{"top":"5px","left":"5px"},attrs:{"name":"solicitudes","structureRef":'widget_solicitudes_clientes',"widget":"handsontable","height":150,"width":705,"minRows":5,"readonly":"","htSettings":{ columnSorting: true, beforeColumnSort: null },"columns":[
        {
          name: 'id',
          header: 'Acciones',
          readOnly: true,
          renderer: _vm.rendererAcciones,
          columnSorting: {
            indicator: false,
            headerAction: false,
            compareFunctionFactory: function () { return function () { return 0; }; }
          }
        },
        {
          name: 'nombre',
          header: 'Nombre',
        },
        {
          name: 'codigo_postal',
          header: 'Código postal',
        },
        {
          name: 'nif_cif',
          header: 'NIF/CIF',
        },
        {
          name: 'email',
          header: 'Email',
        },
        {
          name: 'movil',
          header: 'Móvil',
        },
        {
          name: 'pais_id',
          header: 'País',
          type: 'm2o',
          primary: 'codigo',
          showCode: true,
        }
      ]}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }